import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/components/ef/index.css'
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VueCodemirror from "vue-codemirror";

Vue.use(ElementUI, { size: 'small' })
Vue.use(Antd)
Vue.use(VueCodemirror);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
